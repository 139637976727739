import React, { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import "../../index.css";
import useForm from "./useForm";
import validate from "./validateInfo";

const FormSignup = ({ isCourier, submitForm }) => {
  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    getVehicleTypes,
    vehicle,
  } = useForm(submitForm, validate);

  useEffect(() => {
    getVehicleTypes();
  }, []);

  let isCouriered;
  if (!isCourier) {
    isCouriered = (
      <React.Fragment>
        <Form.Control
          type="text"
          id="user_city"
          name="user_city"
          placeholder="*City"
          className={`inp-form ${errors.user_city ? "error" : ""}`}
          value={values.user_city}
          onChange={handleChange}
          maxLength={12}
        />
        {<div className="error-msg">{errors.user_city}</div>}
      </React.Fragment>
    );
  } else {
    isCouriered = (
      <React.Fragment>
        <Form.Control
          as="select"
          id="user_vehicle_type"
          name="user_vehicle_type"
          className={`inp-form ${errors.user_vehicle_type ? "error" : ""}`}
          value={values.user_vehicle_type}
          onChange={handleChange}
        >
          <option hidden value key={"blankChoice"}>
            Vehicle type{" "}
          </option>
          {Object.values(vehicle).map((vehicle, index) => (
            <option key={index} name={vehicle} value={vehicle}>
              {vehicle}
            </option>
          ))}
        </Form.Control>
        {<div className="error-msg">{errors.user_vehicle_type}</div>}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container>
        <h2 className="form-display-3 text-center pb-4 brand-font">
          Leave us your details
        </h2>
        <Form onSubmit={handleSubmit}>
          <div className="mb-5">
            <Form.Control
              type="text"
              id="user_full_name"
              name="user_full_name"
              placeholder="*Full name"
              className={`inp-form ${errors.user_full_name ? "error" : ""}`}
              value={values.user_full_name}
              onChange={handleChange}
            />
            {<div className="error-msg">{errors.user_full_name}</div>}
          </div>

          {/* New Type Selection */}
          <div className="mb-5">
            <Form.Control
              as="select"
              id="user_type"
              name="user_type"
              className={`inp-form ${errors.user_type ? "error" : ""}`}
              value={values.user_type}
              onChange={handleChange}
            >
              <option hidden value="">
                Select type
              </option>
              <option value="construction">Construction</option>
              <option value="supplier">Supplier</option>
              <option value="driver">Driver</option>
            </Form.Control>
            {<div className="error-msg">{errors.user_type}</div>}
          </div>

          <div className="mb-5">
            <Form.Control
              type="text"
              id="user_phone_number"
              name="user_phone_number"
              placeholder="*Tel"
              className={`inp-form ${errors.user_phone_number ? "error" : ""}`}
              value={values.user_phone_number}
              onChange={handleChange}
              maxLength={12}
            />
            {<div className="error-msg">{errors.user_phone_number}</div>}
          </div>
          <div className="mb-5">
            <Form.Control
              type="text"
              id="user_email"
              name="user_email"
              placeholder="*E-mail"
              className={`inp-form ${errors.user_email ? "error" : ""}`}
              value={values.user_email}
              onChange={handleChange}
            />
            {<div className="error-msg">{errors.user_email}</div>}
          </div>
          {isCouriered}
          <div className="submit-btn-wrapper">
            <Form.Control
              type="submit"
              value="Talk to me"
              className="submit-btn"
              id="submit-btn"
            />
          </div>
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default FormSignup;
