export default function validateInfo(values) {
  let errors = {};

  // FULL NAME
  if (!values.user_full_name?.trim()) {
    errors.user_full_name = "Required field";
  } else if (!/^[\u0590-\u05FFa-zA-Z ,.'-]+$/i.test(values.user_full_name)) {
    errors.user_full_name = "Name can contain only Hebrew and English letters";
  }

  // EMAIL
  if (!values.user_email) {
    errors.user_email = "Required field";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.user_email)
  ) {
    errors.user_email = "Invalid email address";
  }

  // PHONE NUMBER
  if (!values.user_phone_number) {
    errors.user_phone_number = "Required field";
  } else {
    // Israeli formats: 05X-XXXXXXX, 05X XXXXXXX, 05XXXXXXXX
    const israeliPattern = /^(0[23489][-\s]?[2-9]\d{6}|05[0-9][-\s]?\d{7})$/;

    // US formats: (XXX) XXX-XXXX, XXX-XXX-XXXX, XXX XXX XXXX, XXXXXXXXXX
    const usPattern =
      /^(\+1[-\s]?)?(\([0-9]{3}\)|[0-9]{3})[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/;

    if (
      !israeliPattern.test(values.user_phone_number) &&
      !usPattern.test(values.user_phone_number)
    ) {
      errors.user_phone_number = "Invalid phone number";
    }
  }

  // TYPE SELECTION
  if (!values.user_type || values.user_type === "") {
    errors.user_type = "Please select a type";
  }

  // CITY (if applicable)
  if (values.user_city !== undefined) {
    if (!values.user_city?.trim()) {
      errors.user_city = "Required field";
    } else if (!/^[\u0590-\u05FFa-zA-Z\s,.'-]+$/i.test(values.user_city)) {
      errors.user_city =
        "City name can contain only Hebrew and English letters";
    }
  }

  // VEHICLE TYPE (if applicable)
  if (values.user_vehicle_type !== undefined) {
    if (!values.user_vehicle_type || values.user_vehicle_type === "") {
      errors.user_vehicle_type = "Please select a vehicle type";
    }
  }

  return errors;
}
