import $ from "jquery";

const modalPosition = () => {
  let ticking = false;
  let lastScrollPosition = 0;
  const threshold = 5; // מינימום פיקסלים לשינוי

  const updatePosition = () => {
    const download = $("#clients");
    const floatingDiv = $(".registration-form-container");

    if (download.length) {
      const hT = download.offset().top;
      const hH = download.outerHeight();
      const wH = $(window).height();
      const wS = $(window).scrollTop();

      // שומר את המיקום הקודם של הדיב
      const previousPosition = floatingDiv.hasClass("abs");

      // בודק אם צריך להוסיף או להסיר את הקלאס
      const shouldBeAbsolute = wS > hT + hH - wH;

      // מבצע שינוי רק אם באמת צריך
      if (previousPosition !== shouldBeAbsolute) {
        if (shouldBeAbsolute) {
          if (!floatingDiv.hasClass("abs")) {
            floatingDiv.addClass("abs");
          }
        } else {
          if (floatingDiv.hasClass("abs")) {
            floatingDiv.removeClass("abs");
          }
        }
      }
    }

    ticking = false;
  };

  $(window).scroll(function () {
    const currentScrollPosition = $(this).scrollTop();

    // בודק אם השינוי בסקרול מספיק משמעותי
    if (Math.abs(currentScrollPosition - lastScrollPosition) < threshold) {
      return;
    }

    lastScrollPosition = currentScrollPosition;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        updatePosition();
        ticking = false;
      });

      ticking = true;
    }
  });

  // עדכון ראשוני
  updatePosition();
};

export default modalPosition;
